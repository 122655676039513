@import "variables";
@import "node_modules/ingenico-design-system/lib/output.scss";

// Packages
@import "../../node_modules/bootstrap-select/dist/css/bootstrap-select.css";

// Core
@import "breakpoints";
@import "fonts";
@import "icons";
@import "type";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "mixins";
@import "tables";
@import "forms";

// Components
@import "buttons";
@import "navs";
@import "dropdowns";
@import "cards";
@import "carousel";
@import "breadcrumb";
@import "listgroup";
@import "location";
@import "badges";
@import "alerts";
@import "scenarios";
@import "code";
@import "popover";
@import "modal";
@import "toggle-mode";
@import "search";

// Structures
@import "body";
@import "header";
@import "footer";
@import "aside";

// ###### API REFERENCE SPECIFIC SASS
@import "api-reference/type.scss";
@import "api-reference/header.scss";
@import "api-reference/aside.scss";
@import "api-reference/navs.scss";
@import "api-reference/forms.scss";
@import "api-reference/api-filter.scss";
@import "api-reference/api-content.scss";
@import "api-reference/_tb_property-table.scss";
@import "api-reference/scenarios.scss";
@import "api-reference/payment_methods.scss";
@import "api-reference/tables.scss";
@import "api-reference/utilities.scss";

// Darkmode
@import "darkmode";
